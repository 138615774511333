import React, {useState, useRef, Component} from 'react';
import {useLocation, useNavigate, Link} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import '../App.css';
import { Redirect } from 'react-router';
// import AppLayout from "@awsui/components-react/app-layout";
// import FormField from "@awsui/components-react/form-field";
// import Alert from "@awsui/components-react/alert";
// import Container from "@awsui/components-react/container";
// import Header from "@awsui/components-react/header";
// import SideNavigation from '@awsui/components-react/side-navigation';
// import Button from "@awsui/components-react/button";
// import TokenGroup from "@awsui/components-react/token-group";
// import TopNavigation from "@awsui/components-react/top-navigation"
// import SpaceBetween from "@awsui/components-react/space-between";
// import ProgressBar from "@awsui/components-react/progress-bar";
import Amplify, {Auth, Storage} from 'aws-amplify';
// import {Authenticator, Text, Image, View, useTheme} from '@aws-amplify/ui-react';
// import HelpPanel from "@awsui/components-react/help-panel";
// import Icon from "@awsui/components-react/icon";
import awsconfig from '../aws-exports';
// import { CognitoUser } from 'amazon-cognito-identity-js';
import { Navigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css"

Amplify.configure(awsconfig);

Auth.configure({authenticationFlowType: 'CUSTOM_AUTH'});
var loggedIn = false;
var userName = "";
var passWord = "";
var userOTP;
var time;



const Login = () => {
    const navigate = useNavigate();
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    
    const [authenticated, setauthenticated] = useState(window.localStorage.getItem(window.localStorage.getItem("authenticated")|| false));
   
    function handleSubmit() {
            try {
                event.preventDefault();
                Auth.signIn({username, password,
                    options: {
                        authFlowType: 'CUSTOM_WITH_SRP',
                    }
                    })
                    .then(user => {
                        userOTP = user;
                        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            navigate("/resetpassword")
                        }
                        else
                        {
                            userName = username;
                            passWord = password
                            // console.log(user);
                            navigate("/otppage");
                        }
    
                    })
                    .catch(() => {
                        alert("Incorrect UserName or Password!")
                        window.location.reload();
                    });
                // console.log(user);
                // const check = Auth.sendCustomChallengeAnswer(user, 'blue');
                
                // console.log(check);
                // console.log(Auth.currentAuthenticatedUser())
            } catch (error) {
                console.log('error signing in', error);
            }
        
    }
    //forgot password page
    function forgotPassword(){
        try{
            var usernameForgot = prompt('Please enter your username.')
            event.preventDefault();
            userName = usernameForgot;
            Auth.forgotPassword(userName)
                .then(user => {
                    userOTP = user;
                    navigate("/forgotpassword")
                })
                .catch(() => {
                    alert("Incorrect UserName or Password!")
                    window.location.reload();
                });
            // console.log(user);
            // const check = Auth.sendCustomChallengeAnswer(user, 'blue');
            
            // console.log(check);
            // console.log(Auth.currentAuthenticatedUser())
        } catch (error) {
            console.log('error resetting password in', error);
        }
    }
    return (
        
        <div className="Auth-form-container" aria-live="polite">
            <form className="Auth-form" onSubmit={handleSubmit}>
                <div className="Auth-form-content" aria-live="polite">
                    <h1 className="Auth-form-title">SIGN INTO EAS-E</h1>
                    <div className="form-group mt-3" aria-live="polite">
                        <label title="Username" for="username">User Name</label>
                            <input 
                                type="text" 
                                title="Username"
                                name ="username"
                                id="username"
                                tabIndex = "1"
                                className="form-control mt-1"
                                placeholder="Enter your user name"
                                onChange={e => setUserName(e.target.value)}
                            />
                    </div>
                    <div className="form-group mt-3" aria-live="polite">
                        <label title="Password" for="password">Password</label>
                            <input
                                type="password"
                                name="Password"
                                id="password"
                                tabIndex = "2"
                                className="form-control mt-1"
                                placeholder="Enter your password"
                                onChange={e => setPassword(e.target.value)}
                            />
                    </div>
                    <div className="d-grid gap-2 mt-3" aria-live="polite">
                        <button title="Submit Button" type="submit" tabIndex= "3" className="btn btn-primary" onClick={() => handleSubmit}>
                        Submit
                        </button>
                        <div className="d-grid gap-2 mt-3" aria-live="polite">
                        <button title="Forgot Password" type ="text" tabIndex= "4" className="btn btn-secondary" onClick={forgotPassword}>
                        Forgot Password
                        </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
//otppage
const OTPPAGE = () => {
    const navigate = useNavigate();
    const [answer, setAnswer] = useState("");
    const [authenticated, setauthenticated] = useState(window.localStorage.getItem(window.localStorage.getItem("authenticated")|| false));
    var attempts = 0;
    var otpAnswer = "";
    function handleSubmit(otpanswer) {
        try {
            event.preventDefault();
            
            // console.log(userOTP)
            // console.log(userName)
            // console.log(otpanswer)
            // console.log(userOTP.getSignInUserSession())
            Auth.sendCustomChallengeAnswer(userOTP, otpanswer)
                .then(user => {
                    // console.log(user);
                    Auth.currentAuthenticatedUser()
                        .then(user => {
                            // alert("Per the government  shutdown, this page is not accessible.")
                            // userOTP = null;
                            // userName = "";
                            // navigate("/login")
                            setauthenticated(true);
                            loggedIn = true;
                            window.localStorage.setItem("authenticated", true);
                            window.onload = function() {
                                console.log("start")
                                
                            }
                            alert("You are accessing a US Government Page." +" " +
                                "This system is for US Government-authorized use only." + " " +
                                "The US Government can monitor and acquire, all data transfered to or from, stored on, or transiting this system." + " "  +
                                "By using this system you acknowledge that you understand and consent to all of the above."
                                )
                            
                            navigate("/content", {state:{authLoggedIn:loggedIn, authUserName:userName, authenticated}});
                            passWord = "";
                            console.log("success logon")
                        })
                })
                .catch(e => {
                    attempts++;
                    // console.log(e)
                    console.log(attempts);
                    if (attempts >=1) {
                        alert("Max Attempts Reached! Please Log Back In.")
                        userOTP = null;
                        userName = "";
                        navigate("/login")
                    }
                    else{
                        alert("Incorrect OTP! Please try again a new OTP is being sent to your email.");
                        // console.log(userName);
                        let username = userName.toString();
                        let password = passWord.toString();
                        Auth.signIn({username, password,
                            options: {
                                authFlowType: 'CUSTOM_WITH_SRP',
                            }
                            }).then(user => {
                                userOTP = user;
                            })
                    }
                });
            
            
                    
            // console.log(user);
            // const check = Auth.sendCustomChallengeAnswer(user, 'blue');
            
            // console.log(check);
            // console.log(Auth.currentAuthenticatedUser())
        } catch (error) {
            console.log('error signing in', error);
            navigate("/login");
        }
    }
    if (userOTP != null){
        return (
            <div className="Auth-form-container"aria-live="polite">
                <form className="Auth-form">
                    <div className="Auth-form-content"aria-live="polite">
                        <h3 title="Enter OTP" className="Auth-form-title" for="otp">Enter OTP</h3>
                        <div className="form-group mt-3">
                            <label title="check email for code" for="information">Check your email for the code from</label>
                          <div className="form-group mt-2" aria-live="polite">
                            <label for="email">no-reply@goldcherrygumdrop.com</label>
                                </div>
                                <input
                                    title="Enter OTP From Email"
                                    type="text"
                                    name="One Time Password"
                                    id="otp"
                                    tabIndex="1"
                                    className="form-control mt-1"
                                    placeholder="Enter the OTP code recieved from email."
                                    onChange={e => {otpAnswer = e.target.value}}
                                />
                                </div>
                            <div className="form-group mt-3" aria-live="polite"> 
                            <label> You should be recieving an email within 5 minutes. </label>
                            </div>
                        <div className="d-grid gap-2 mt-3" aria-live="polite">
                       
                        
                            <button type="submit" title="Submit" tabIndex="2" className="btn btn-primary" onClick={() => handleSubmit(otpAnswer)}>
                            Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    else {
        return <Navigate replace to="/login" />;
    }
    
};
//reset password page
const ResetPassword = () => {
    const navigate = useNavigate();
    const [newPassword, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    function handleSubmit() {
        try {
            event.preventDefault();
            // console.log(userOTP)
            var re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
            if (newPassword ==confirmPassword){
                if (re.test(newPassword) && newPassword.length > 11){
                    Auth.completeNewPassword(
                        userOTP,               // the Cognito User Object
                        newPassword,        // the new password
                    ).then(user => {
                        alert("Please log in with your new password.")
                        userOTP = null;
                        userName = "";
                        navigate("/login")
                    }).catch(e => {
                        alert("Password change failed, please try a different password.")
                    })

                }
                else{
                    alert("Password must be at least 12 characters in length, contain an UPPERCASE, lowercase, Number, and special character.")
                }
            }
            else{
                alert("Passwords do not match.")
            }
        } catch (error) {
            console.log('error signing in', error);
            navigate("/login");
        }
    }
    if (userOTP != null)
    {
        return (
            <div className="Auth-form-container" aria-live="polite">
                <form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content">
                        <h3 title="Enter New Password" className="Auth-form-title">Enter New Password</h3>
                        <div className="form-group mt-3">
                            <label title="Enter password" for="password">Please Enter a New Password</label>
                                <input
                                    type="password"
                                    name="Password"
                                    title="Password"
                                    id="password"
                                    tabIndex="1"
                                    className="form-control mt-1"
                                    placeholder="Please Enter a new Password"
                                    onChange={e => setPassword(e.target.value)}
                                /></div>
                                <div className="form-group mt-3" aria-live="polite">
                                <input
                                    type="password"
                                    name="password"
                                    title="Confirm Password"
                                    id="password"
                                    tabIndex="2"
                                    className="form-control mt-1"
                                    placeholder="Please confirm your new Password"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                </div>
                                <div className="form-group mt-3" aria-live="polite">
                                 <label> *Password must be at least 12 characters in length, and must contain an UPPERCASE, lowercase, Number, and a Special Character.</label>
                                 </div>
                        
                                    <div className="d-grid gap-2 mt-3" aria-live="polite">
                                    <button tabIndex="3 "type="submit" title="Submit" className="btn btn-primary" onClick={() => handleSubmit}>
                                     Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }    else {
        return <Navigate replace to="/login" />;
    }
};
//forgot password page
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [newPassword, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [code, setCode] = useState("");
    function handleSubmit() {
        try {
            event.preventDefault();
            // console.log(userName)
            if (newPassword ==confirmPassword){
                var re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
                if (re.test(newPassword) && newPassword.length > 11){
                    Auth.forgotPasswordSubmit(
                        userName,               // the Cognito User Object
                        code,
                        newPassword,        // the new password
                    ).then(user => {
                        alert("Please log in with your new password.")
                        userOTP = null;
                        userName = "";
                        navigate("/login")
                    }).catch(e => {
                        alert("Password change failed, please try a different password.")
                    })

                }else{
                    alert("Password must be at least 12 characters in length, contain an UPPERCASE, lowercase, Number, and special character.")
                }
            }
            else{
                alert("Passwords do not match.")
            }
        } catch (error) {
            console.log('error signing in', error);
            navigate("/login");
        }
    }
    if (userName != "")
    {
        return (
            <div className="Auth-form-container" aria-live="polite">
                <form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">Forgot Password</h3>
                        <div className="form-group mt-3" aria-live="polite">
                            <label title="Enter code emailed to you">Please Enter the Code Emailed to You:</label>
                                <input
                                    type="text"
                                    name="otp"
                                    title="otp"
                                    id="otp"
                                    tabIndex="1"
                                    className="form-control mt-1"
                                    placeholder="Enter the code emailed to you."
                                    onChange={e => setCode(e.target.value)}
                                /></div>
                                <div className="form-group mt-3" aria-live="polite">
                                 <label for="password">Please Enter a New Password:</label>
                                <input
                                    type="password"
                                    name="password"
                                    title="password"
                                    id="password"
                                    tabIndex="2"
                                    className="form-control mt-1"
                                    placeholder="Enter New Password"
                                    onChange={e => setPassword(e.target.value)}
                                />
                                </div>
                                <div className="form-group mt-3" aria-live="polite">
                                 <label for="password" title="Confirm Password">Please Confirm the New Password:</label>
                                <input
                                    type="password"
                                    name="password"
                                    title="new password"
                                    id="password"
                                    tabIndex="3"
                                    className="form-control mt-1"
                                    placeholder="Confirm New Password"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                </div>
                        <div className="form-group mt-3 "aria-live="polite">
                                 <label for="password" title="Password must be at least 12 characters in length, and must contain an UPPERCASE, lowercase, Number, and a Special Character"> Password must be at least 12 characters in length, and must contain an UPPERCASE, lowercase, Number, and a Special Character.</label>
                                 </div>
                        <div className="d-grid gap-2 mt-3" aria-live="polite">
                            <button tabIndex="4" type="submit" title="submit" className="btn btn-primary" onClick={() => handleSubmit}>
                            Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    else {
        return <Navigate replace to="/login" />;
    }
};

//  alert("You are accessing a US Government information system, which includes (1) this computer, (2) this computer network, (3) all computers connected to this network,and (4) all devices and strage media attached to this network or a computer on this network." + " " +
//                                 "this information system is for US Government-authorized use only. Unauthorized or imrpoper use of this system may result in discplinary action, as well as civil and criminal penalties." + " " +
//                                 "You have no reasonable expectation of privacy regarding any communications or data transmitted to or from, stored on, or transiting this information system." + " " +
//                                 "The US Government monitors, intercepts, acquires, searches, and/or seizes all data transmitted to or from, stored on,  or transiting this information system, and will retain, and perodically review, use, and disclose such communications or data for security, intelligence, and other lawful purposes." + " " +
//                                 "The communications and data subject to the activities described in this paragraph include the content of: personal communications, messages, emails, website browsing, and any other online account or service sent to this information system, even if you never request, access, or view such content on this information system." + " " +
//                                 "All of this information would be subject to collection, use, retention, and review." + " " +
//                                 "By using this information system you acknowledge that you understand and consent to all of the above."
//                                 )
export{Login, ForgotPassword, OTPPAGE,ResetPassword};